interface Wise {
  disclaimer: { title: string, text1: string, text2: string }
}

export const WISE: Wise = {
  disclaimer: {
    title: 'USD Funds Agreement',
    text1: `By depositing USD funds in my USD addy wallet I acknowledge that there will be a fee when I decide to make a withdrawal from that wallet. (Fees depend on a number of factors including your chosen withdrawal method, please `,
    text2: 'for more information.)'
  },
}
