<template>
  <div class="wise-transfer-wrap">
    <NavBack/>
    <div class="fixed-background sticky-header">
      <MenuAndLogo></MenuAndLogo>
    </div>
    <div class="has-padding">
      <div class="page-title has-text-weight-semibold has-text-centered mb-3">Add Funds to your USD addy wallet account</div>
      <ol class="main has-background-white">
        <li>Send an email to
          <a href="mailto:support@addyinvest.com?subject=Fund my USD Wallet" class="has-text-primary">support@addyinvest.com</a>
        </li>
        <li>Subject line: Fund my USD Wallet</li>
        <li>Let us know how much in <b class="has-text-weight-semibold">US dollars</b> you’d like to fund your addy wallet with</li>
        <li>Include your addy handle: <span class="has-text-blue">{{ addyHandle }}</span></li>
        <li>We'll send you a link to add funds</li>
      </ol>
    </div>
    <ConfirmModal
      :title="disclaimerTitle"
      :showModal="showConsentModal"
      continueButtonText="Continue"
      cancelButtonText="Go back"
      :hasCloseIcon="false"
      :hasClickableBackground="false"
      :hasCancelButtonOnLeft="true"
      :continueButtonDisabled="isContinueButtonDisabled"
      @cancelAction="onCancel"
      @doContinue="onConfirm"
    >
      <div class="confirm-modal-text has-text-left">
        {{ disclaimerText1 }}
        <a
          target="_blank"
          href="https://support.addyinvest.com/information-for-users-and-members/joURyc3sAhBcx9jNmWJ8M2/possible-fees-associated-with-usd-wallets/3g8b3mrGKcmQczkRz83c2w"
          class="has-text-blue has-text-weight-semibold"
        >click here</a>
        {{ disclaimerText2 }}
      </div>
      <b-field label='To confirm, type "I accept" in the text input field.' class="i-accept-field pb-4">
        <b-input v-model.trim="iAccept" placeholder="I accept"></b-input>
      </b-field>
    </ConfirmModal>
  </div>
</template>
<script>
import NavBack from '@components/desktop/header-nav-back.vue'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import ConfirmModal from '@components/modal/continue-or-cancel-modal.vue'
import { WISE } from '@utils/data/wise.ts'
import { acceptUsFundTransferAgreement } from '@api/wallet'

export default {
  components: {
    NavBack,
    MenuAndLogo,
    ConfirmModal,
  },
  data() {
    return {
      disclaimerTitle: WISE.disclaimer.title,
      disclaimerText1: WISE.disclaimer.text1,
      disclaimerText2: WISE.disclaimer.text2,
      showConsentModal: true,
      iAccept: null,
    }
  },
  computed: {
    isContinueButtonDisabled() {
      return !(this.iAccept?.toLocaleLowerCase() === 'i accept')
    },
    addyHandle() {
      const investor = localStorage.getItem('investor')
      return investor && JSON.parse(investor).addyHandle
    },
  },
  methods: {
    onConfirm() {
      acceptUsFundTransferAgreement().then((res) => {
        if (res.success) {
          this.showConsentModal = false
        } else {
          this.$buefy.toast.open({
            message: res.error,
            duration: 3000,
            type: 'is-danger',
          })
        }
      })
    },
    onCancel() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

@media only screen and (min-width: $min-viewport-width) {
  .wise-transfer-wrap {
    min-height: $min-height-mobile-container;
    max-width: $mobile-wrapper-width;
    position: unset;
  }
}

.wise-transfer-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  overflow: auto;
  padding-bottom: 30px;
  .has-padding {
    padding: 18px 25px 0;
    .page-title {
      font-size: 26px;
      line-height: 32px;
      margin-bottom: 16px;
      letter-spacing: 0.03em;
    }
    .main {
      font-size: 16px;
      border-radius: 12px;
      box-shadow: 0px 30px 50px rgba(215, 145, 51, 0.11);
      margin-bottom: 30px;
      padding: 30px 22px 22px 34px;
      min-height: 300px;
      li {
        margin-bottom: 16px;
        line-height: 20px;
      }
    }
  }
  ::v-deep .modal .modal-content {
    .modal-text {
      text-align: left;
    }
    .i-accept-field {
      padding: 0 20px;
      .label {
        text-align: left;
      }
    }
  }
  .confirm-modal-text {
    font-size: 16px;
    line-height: 20px;
    padding: 0 20px 20px;
    margin-top: -20px;
  }
}
</style>
